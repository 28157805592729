(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const r = document.querySelector(':root');
  const switcherItem = document.querySelectorAll('.switcher__item');

  const jobsWrapper = document.querySelector(".intro__jobs");
  const jobs = jobsWrapper.querySelectorAll(".intro__job");
  const currentJob = jobsWrapper.querySelector(".intro__job--current");
  const jobWidth = Array.from(jobs).map((job) => job.offsetWidth);
  const maxJobsWidth = Math.max(...jobWidth);
  const CURRENT_CLASS = "intro__job--current";
  const NEXT_CLASS = "intro__job--next";

  function handleDOMContentLoaded() {
    console.log(jobsWrapper)
    jobsWrapper.style.setProperty("--width", `${currentJob.offsetWidth}px`);
    jobsWrapper.style.setProperty("--width-mobile", `${maxJobsWidth}px`);

    setInterval(() => {
      const currentWord = jobsWrapper.querySelector(".intro__job--current");
      const nextWord = jobsWrapper.querySelector(".intro__job--next");
      const nextNextWord = nextWord.nextElementSibling
        ? nextWord.nextElementSibling
        : jobsWrapper.firstElementChild;
      currentWord.classList.remove(CURRENT_CLASS);
      nextWord.classList.remove(NEXT_CLASS);
      nextWord.classList.add(CURRENT_CLASS);
      nextNextWord.classList.add(NEXT_CLASS);
      jobsWrapper.style.setProperty("--width", `${nextWord.offsetWidth}px`);
    }, 2500);

    switcherItem.forEach((el) => {
      el.addEventListener('click', handleSwitcherItemClick);
    });
  }

  function handleSwitcherItemClick(e) {
    e.preventDefault();
    const visibleOption = e.currentTarget.dataset.visibleOption;

    r.style.setProperty('--visible-1', visibleOption === '1' ? 'initial' : 'none');
    r.style.setProperty('--visible-2', visibleOption === '1' ? 'none' : 'initial');

    switcherItem.forEach((el) => el.classList.remove('switcher__item--selected'));

    e.currentTarget.classList.add('switcher__item--selected');
  }

})();
